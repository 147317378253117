.image-links {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	justify-content: center;
	align-items: center;
	&__link {
		border-radius: 5px;
		padding: 1rem;
		@include media-breakpoint-down(sm) {
			width: 50% !important;
		}
		&__image {
			aspect-ratio: 16 / 9;
			transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
			will-change: transform;
			object-fit: contain;

			@include media-breakpoint-down(sm) {
				width: 100% !important;
			}
		}
		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
			& .image-links__link__image {
				transform: scale(0.9);
			}
		}
	}
}
