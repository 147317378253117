.page {
  position: relative;
  height: auto;
  &_header {
    padding-top: 200px;
    padding-bottom: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    &_overlay {
      pointer-events: none;
      user-select: none;
      background-color: $primary-dark;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: 1;
    }
    &_decoration {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: auto;
      z-index: 1;
    }
    &_title {
      margin-bottom: 0 !important;
    }
    &_content {
      z-index: 2;
      position: relative;
    }
  }
  &_decoration {
    user-select: none;
    pointer-events: none;
    position: absolute;
    height: 135%;
    left: -40%;
    z-index: 0;
    -webkit-mask-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.2) 50%,
      black 100%
    );
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.2) 50%, black 100%);
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
  &_content-wrapper {
    position: relative;
    overflow: hidden;
    min-height: 500px;
  }
  &_content {
    position: relative;
    z-index: 1;
  }
}
.page-bg {
  object-fit: cover;
  mask-image: linear-gradient(to bottom, black 75%, transparent 95%);
  z-index: -1;
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100vh;
  opacity: 0.3;
}
