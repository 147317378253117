.btn {
	font-size: 1.25rem;
	padding: 0.75rem 1.5rem;
}
.hover-scale {
	@include media-breakpoint-up(sm) {
		transition: all 0.15s $smooth;
		transform-origin: center;
		&:hover {
			transform: scale(1.05);
			cursor: pointer;
		}
	}
}

/* From uiverse.io */
.btn-glow {
	--glow-color: rgb(217, 176, 255);
	--glow-spread-color: rgba(191, 123, 255, 0.781);
	--enhanced-glow-color: rgb(231, 206, 255);
	--btn-color: rgb(100, 61, 136);
	border: 0.25em solid var(--glow-color);
	padding: 1em 3em;
	color: white;
	font-size: 15px;
	font-weight: bold;
	background-color: var(--btn-color);
	border-radius: 1em;
	outline: none;
	box-shadow: 0 0 1em 0.25em var(--glow-color),
		0 0 4em 1em var(--glow-spread-color),
		inset 0 0 0.75em 0.25em var(--glow-color);
	text-shadow: 0 0 0.5em var(--glow-color);
	position: relative;
	transition: all 0.3s;
}

.btn-glow::after {
	pointer-events: none;
	content: '';
	position: absolute;
	top: 120%;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: var(--glow-spread-color);
	filter: blur(2em);
	opacity: 0.7;
	transform: perspective(1.5em) rotateX(35deg) scale(1, 0.6);
}

.btn-glow:hover {
	color: var(--btn-color);
	background-color: var(--glow-color);
	box-shadow: 0 0 1em 0.25em var(--glow-color),
		0 0 4em 2em var(--glow-spread-color),
		inset 0 0 0.75em 0.25em var(--glow-color);
}

.btn-glow:active {
	box-shadow: 0 0 0.6em 0.25em var(--glow-color),
		0 0 2.5em 2em var(--glow-spread-color),
		inset 0 0 0.5em 0.25em var(--glow-color);
}
