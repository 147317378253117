/* Variables ........................ */
$primary: #1199da;
$primary-dark: #131530;
$secondary: #84378c;
$light: #fff;

$body-bg: $primary-dark;
$body-color: #fff;
$border-radius: 0.5rem;

// Transitions
$smooth: cubic-bezier(0.39, 0.575, 0.565, 1);
$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1400px,
);

figure img {
  height: max-content;
}
img {
  image-rendering: -webkit-optimize-contrast;
}

// Bootstrap
@import '~bootstrap/scss/bootstrap';

.col-6,
.col-auto {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
@keyframes fadein {
  100% {
    opacity: 1;
  }
}
.bg-radial {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: -1;
  opacity: 0.8;
  @include media-breakpoint-down(sm) {
    opacity: 0.5;
  }
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.card-img-top {
  max-height: 500px;
}
/* Partials ......................... */
@import 'partials/mixins';
@import 'partials/global';
@import 'partials/typography';
@import 'partials/utilities';
@import 'partials/forms';

/* Components ....................... */
@import 'components/VideoIntro';
@import 'components/Bg';
@import 'components/Btn';
@import 'components/Nav';
@import 'components/Page';
@import 'components/UmbracoComponents';
@import 'components/Richtext';
@import 'components/Slider';
@import 'components/UmbracoCard';
@import 'components/Footer';
@import 'components/FAQ';
@import 'components/ImageLinks';
@import 'components/Cta';

/* Routes ........................... */
@import 'routes/Home';

.cta-btn {
  text-decoration: none;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.2) 54%,
    rgba(255, 255, 255, 0.1) 100%
  );
  width: max-content;
  /* min-width: 200px; */
  border: 0;
  border-radius: 0.3rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 1rem 0.5rem;
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  text-underline-position: under;
  user-select: none;
  position: relative;
}

.cta-btn__icon {
  transform: translateX(150%);
  transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn__label {
  display: inline-block;
  margin-left: 2rem;
  /* transform: translateX(1rem); */
  transition: all 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.cta-btn:hover {
  opacity: 0.95;
  text-decoration: underline;
}
.cta-btn::before,
.cta-btn::after {
  content: '';
  opacity: 0.5;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation: ripple 1s linear infinite;
  animation-play-state: paused;
  transform: scale(0.8);
  position: absolute;
}
.cta-btn::before {
  left: 0;
}
.cta-btn::after {
  right: 0;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
      0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1),
      0 0 0 60px rgba(255, 255, 255, 0.1);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
      0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1),
      0 0 0 80px rgba(255, 255, 255, 0);
  }
}

.cta-btn:before,
.cta-btn:after {
  animation-duration: 2s;
  animation-play-state: running;
}
.cta-btn:hover:before {
  opacity: 0;
  animation-play-state: paused;
}
.cta-btn {
  padding-right: 3rem;
}
.cta-btn .cta-btn__icon {
  transform: translateX(1.5rem);
}
.cta-btn .cta-btn__label {
  transform: translateX(-0.5rem);
}
.cta-btn:hover:after {
  animation-duration: 1s;
  opacity: 1;
  transform: scale(1);
  border-radius: 100%;
  opacity: 1;
  animation-play-state: running;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
