.footage-wrapper {
    -webkit-filter: url(#colorFilter2);
    filter: url(#colorFilter2);
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
}
.skip-intro {
    position: fixed;
    bottom: 10vh;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    transform-origin: center;
    &-hide {
        transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: scale(0.1) translateX(-50%);
        opacity: 0;
    }
}

#canvas-target {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(1.5);
    // min-height: 100vh;
    // min-width: 100vw;
    object-fit: cover;
    display: block;
    overflow: hidden;
    object-position: 50% 50%; /* default value: image is centered*/
}

#canvas-processor {
    display: none;
}

#video-green-screen {
    opacity: 0;
    pointer-events: none;
}
.video-intro {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    pointer-events: none;
    &--hide {
        opacity: 0;
    }
}
