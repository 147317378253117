.cmdk-box {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --font-sans: 'Inter', --apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  --cmdk-shadow: 0 16px 70px #000;
  --lowContrast: #fff;
  --highContrast: #000;
  --umbraco: #3644a7;
  --gray1: #fcfcfc;
  --gray2: #f8f8f8;
  --gray3: #f3f3f3;
  --gray4: #ededed;
  --gray5: #e8e8e8;
  --gray6: #e2e2e2;
  --gray7: #dbdbdb;
  --gray8: #c7c7c7;
  --gray9: #8f8f8f;
  --gray10: #858585;
  --gray11: #6f6f6f;
  --gray12: #171717;
  --grayA1: rgba(0, 0, 0, 0.012);
  --grayA2: rgba(0, 0, 0, 0.027);
  --grayA3: rgba(0, 0, 0, 0.047);
  --grayA4: rgba(0, 0, 0, 0.071);
  --grayA5: rgba(0, 0, 0, 0.09);
  --grayA6: rgba(0, 0, 0, 0.114);
  --grayA7: rgba(0, 0, 0, 0.141);
  --grayA8: rgba(0, 0, 0, 0.22);
  --grayA9: rgba(0, 0, 0, 0.439);
  --grayA10: rgba(0, 0, 0, 0.478);
  --grayA11: rgba(0, 0, 0, 0.565);
  --grayA12: rgba(0, 0, 0, 0.91);
  --blue1: #fbfdff;
  --blue2: #f5faff;
  --blue3: #edf6ff;
  --blue4: #e1f0ff;
  --blue5: #cee7fe;
  --blue6: #b7d9f8;
  --blue7: #96c7f2;
  --blue8: #5eb0ef;
  --blue9: #0091ff;
  --blue10: #0081f1;
  --blue11: #006adc;
  --blue12: #00254d;
}
.cmdk-box ::placeholder {
  color: var(--gray9);
}
.cmdk-box *:focus::placeholder {
  color: var(--gray9);
}
.cmdk-box button,
.cmdk-box input,
.cmdk-box select,
.cmdk-box textarea {
  color: inherit;
  padding: 0.5rem 1rem 0.75rem 1rem;
}
.cmdk-dialog {
  display: none;
  position: fixed; /* Stay in place */
  z-index: 998; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  font-weight: 400;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  color: var(--gray12);
}

.cmdk-dialog--open {
  display: block;
}
.cmdk-box {
  position: fixed;
  height: max-content;
  background-color: #fff;
  border-radius: 0.4rem;
  overflow: hidden;
  isolation: isolate;
  inset: 0;
  margin: auto;
  padding: 0;
  font-family: var(--font-sans);
  box-shadow: var(--cmdk-shadow);
  border: 1px solid var(--gray6);
  width: 100%;
  max-width: 650px;
}
.cmdk-input {
  width: 100%;
  display: block;
  border: 0;
  padding: 1rem;
  outline: 0;
}
.cmdk-item {
  user-select: none;
  display: flex;
  cursor: pointer;
  height: 35px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1;
  gap: 1rem;
  padding: 0 1rem;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  color: var(--gray12);
  padding-right: 0;
  width: calc(100% - 20px);
}
.cmdk-item--first {
  margin-left: 10px;
  margin-right: 25px;
  margin-bottom: 5px;
  height: 35px;
  padding-right: 14px;
  width: calc(100% - 35px);
}
.cmdk-item svg {
  font-size: 1em;
  transform: scale(1.5);
}
.cmdk-item__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-bottom: 0.5rem;
  margin-bottom: -0.5rem;
}
.cmdk,
.cmdk input,
.cmdk select,
.cmdk textarea {
  color: inherit;
}

.cmdk-item:hover {
  box-shadow: inset 0px 0px 0px 2px var(--gray4);
}
.cmdk-item--selected {
  background-color: var(--gray4);
  /* background: linear-gradient(to right, var(--gray4), var(--gray3)); */
  /* color: var(--gray12); */
}
.umb-btn-arrow {
  display: none;
}
.cmdk-item--selected .umb-btn-arrow {
  display: block;
}
.umb-btn {
  margin-left: auto;
  background-color: #dbdef5;
  color: var(--umbraco);
  border-radius: 8px;
  padding: 8px 0;
  width: 2rem;
  line-height: 1;
  display: grid;
  place-content: center;
}
.cmdk-item.current {
  font-weight: 700;
  color: var(--umbraco);
}
