// /* From uiverse.io by @Navarog21 */
// .cta-btn {
// 	position: relative;
// 	height: 4rem;
// 	padding-left: 3rem;
// 	padding-right: 3rem;
// 	width: max-content;
// 	outline: none;
// 	transition: 0.1s;
// 	background-color: transparent;
// 	border: none;
// 	font-size: 1rem;
// 	font-weight: bold;
// 	color: #ddebf0;
// 	writing-mode: horizontal-tb !important;
// 	display: grid;
// 	place-content: center;
// 	text-decoration: none;
// 	text-underline-offset: 0.2rem;
// 	&:not(:hover) {
// 		text-decoration: underline;
// 	}
// }
// .cta-btn__content {
// 	text-align: center;
// }
// .cta-btn__clip {
// 	--color: #2761c3;
// 	position: absolute;
// 	top: 0;
// 	overflow: hidden;
// 	width: 100%;
// 	height: 100%;
// 	border: 5px double var(--color);
// 	box-shadow: inset 0px 0px 15px #195480;
// 	-webkit-clip-path: polygon(
// 		30% 0%,
// 		70% 0%,
// 		100% 30%,
// 		100% 70%,
// 		70% 100%,
// 		30% 100%,
// 		0% 70%,
// 		0% 30%
// 	);
// }

// .cta-btn__arrow {
// 	position: absolute;
// 	transition: 0.2s;
// 	background-color: $primary;
// 	top: 35%;
// 	width: 11%;
// 	height: 30%;
// }

// .cta-btn__leftArrow {
// 	// left: -13.5%;
// 	-webkit-clip-path: polygon(100% 0, 100% 100%, 0 50%);
// }

// .cta-btn__rightArrow {
// 	-webkit-clip-path: polygon(100% 49%, 0 0, 0 100%);
// 	// left: 102%;
// }

// .cta-btn .cta-btn__rightArrow {
// 	left: -8%;
// }

// .cta-btn .cta-btn__leftArrow {
// 	left: 95%;
// }
// .cta-btn:not(:hover) .cta-btn__rightArrow {
// 	background-color: rgb(184, 39, 251);
// 	left: -15%;
// 	animation: 0.6s ease-in-out both infinite alternate rightArrow8;
// }

// .cta-btn:not(:hover) .cta-btn__leftArrow {
// 	background-color: rgb(184, 39, 251);
// 	left: 103%;
// 	animation: 0.6s ease-in-out both infinite alternate leftArrow8;
// }

// .cta-btn__corner {
// 	position: absolute;
// 	width: 4rem;
// 	height: 4rem;
// 	background-color: $primary;
// 	box-shadow: inset 1px 1px 8px $primary;
// 	transform: scale(1) rotate(45deg);
// 	transition: 0.2s;
// }

// .cta-btn__rightTop {
// 	top: -1.98rem;
// 	left: 91%;
// }

// .cta-btn__leftTop {
// 	top: -1.96rem;
// 	left: -3rem;
// 	filter: brightness(60%);
// }

// .cta-btn__leftBottom {
// 	top: 2.1rem;
// 	left: -2.15rem;
// }

// .cta-btn__rightBottom {
// 	top: 45%;
// 	left: 88%;
// 	filter: brightness(60%);
// }

// .cta-btn:not(:hover) .cta-btn__leftTop {
// 	animation: 0.1s ease-in-out 0.05s both changeColor8,
// 		0.2s linear 0.4s both lightEffect8;
// }

// .cta-btn:not(:hover) .cta-btn__rightTop {
// 	animation: 0.1s ease-in-out 0.15s both changeColor8,
// 		0.2s linear 0.4s both lightEffect8;
// }

// .cta-btn:not(:hover) .cta-btn__rightBottom {
// 	animation: 0.1s ease-in-out 0.25s both changeColor8,
// 		0.2s linear 0.4s both lightEffect8;
// }

// .cta-btn:not(:hover) .cta-btn__leftBottom {
// 	animation: 0.1s ease-in-out 0.35s both changeColor8,
// 		0.2s linear 0.4s both lightEffect8;
// }

// .cta-btn:not(:hover) .cta-btn__corner {
// 	transform: scale(1.25) rotate(45deg);
// }

// .cta-btn:not(:hover) .cta-btn__clip {
// 	animation: 0.2s ease-in-out 0.55s both greenLight8;
// 	--color: rgb(184, 39, 251);
// }

// @keyframes changeColor8 {
// 	from {
// 		background-color: #2781c3;
// 	}

// 	to {
// 		background-color: rgb(184, 39, 251);
// 	}
// }

// @keyframes lightEffect8 {
// 	from {
// 		box-shadow: 1px 1px 5px rgb(184, 39, 251);
// 	}

// 	to {
// 		box-shadow: 0 0 2px rgb(184, 39, 251);
// 	}
// }

// @keyframes greenLight8 {
// 	from {
// 	}

// 	to {
// 		box-shadow: inset 0px 0px 32px rgb(184, 39, 251);
// 	}
// }

// @keyframes leftArrow8 {
// 	from {
// 		transform: translate(0px);
// 	}

// 	to {
// 		transform: translateX(10px);
// 	}
// }

// @keyframes rightArrow8 {
// 	from {
// 		transform: translate(0px);
// 	}

// 	to {
// 		transform: translateX(-10px);
// 	}
// }

// https://uiverse.io/zjssun/curly-seahorse-76
.button {
  appearance: none;
  -webkit-appearance: none;
  user-select: none;
  position: relative;
  padding: 0.5rem 2rem;
  border-radius: 50rem;
  border: none;
  color: #fff;
  cursor: pointer;
  --burst-color-1: rgba(0, 237, 181, 1);
  --burst-color-2: rgba(0, 114, 221, 1);
  background: rgb(0, 237, 181);
  background: linear-gradient(
    139deg,
    rgba(0, 237, 181, 1) 0%,
    rgba(0, 114, 221, 1) 100%
  );
  transition: all 0.2s ease;
  text-decoration: none;
  display: block;
  width: max-content;
}
.button:hover:before {
  text-decoration: underline;
}

.button:hover {
  transform: scale(1.05) !important;
}
.button:active {
  transform: scale(0.96);
}
.button:hover {
  cursor: pointer;
}
.button:before,
.button:after {
  position: absolute;
  content: '';
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1000;
  background-repeat: no-repeat;
}
.button:hover:before {
  top: -70%;
  background-image: radial-gradient(
      circle,
      var(--burst-color-1) 20%,
      transparent 20%
    ),
    radial-gradient(
      circle,
      transparent 20%,
      var(--burst-color-1) 20%,
      transparent 30%
    ),
    radial-gradient(circle, var(--burst-color-1) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-1) 20%, transparent 20%),
    radial-gradient(
      circle,
      transparent 10%,
      var(--burst-color-1) 15%,
      transparent 20%
    ),
    radial-gradient(circle, var(--burst-color-1) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-1) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-1) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-1) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: greentopBubbles 0.6s ease forwards;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      40% 90%, 55% 90%, 70% 90%;
  }

  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 20%, 90% 30%;
  }
  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%,
      50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button:hover::after {
  bottom: -70%;
  background-image: radial-gradient(
      circle,
      var(--burst-color-2) 20%,
      transparent 20%
    ),
    radial-gradient(circle, var(--burst-color-2) 20%, transparent 20%),
    radial-gradient(
      circle,
      transparent 10%,
      var(--burst-color-2) 15%,
      transparent 20%
    ),
    radial-gradient(circle, var(--burst-color-2) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-2) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-2) 20%, transparent 20%),
    radial-gradient(circle, var(--burst-color-2) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: greenbottomBubbles 0.6s ease forwards;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
  }

  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
