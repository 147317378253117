html {
  font-size: 20px;
}
body {
  font-family: 'Karla', sans-serif;
  font-weight: 300;
}
pre {
  color: inherit;
}
h1,
h2,
h4,
h5,
h6 {
  font-family: 'Alegreya Sans', sans-serif;
}
h1 {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 2rem;
  font-weight: bold;
  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
  &.lg {
    font-family: 'Karla', sans-serif;
    font-size: 7rem;
    line-height: 7rem;
    margin-bottom: 2rem;
    @include media-breakpoint-down(lg) {
      font-size: 5rem;
      line-height: 5rem;
    }
    @include media-breakpoint-down(sm) {
      font-size: 4rem;
      line-height: 4rem;
    }
    @include media-breakpoint-only(xs) {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}
a,
a:hover {
  color: inherit;
  text-decoration: underline;
}
a,
button,
.btn {
  font-family: 'Karla', sans-serif;
  font-weight: 500;
}
.richtext {
  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }
  & p {
    @include media-breakpoint-down(sm) {
      text-align: left !important;
    }
  }
}
