.swiper-container-images {
  max-width: 100%;
  overflow: visible !important;
  display: grid;
  gap: 2rem;
  @include media-breakpoint-down(sm) {
    transform: scale(0.8);
    & .swiper-slide-active .swiper-item {
      transform: scale(0.85);
      z-index: 2;
      &:hover {
        transform: scale(0.9);
      }
    }
    margin-bottom: 0;
  }
  & .swiper_buttons {
    grid-row: 3;
  }
  & .swiper-wrapper {
    // align-items: center;
    // width: 150vw;
    align-items: center;
    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
    &:active {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
  & .swiper-pagination {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    position: relative !important;
    bottom: 0 !important;
    z-index: 3;
    & .swiper-pagination-bullet {
      background-color: transparent !important;
      color: white;
      opacity: 0.3;
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      line-height: 1rem;
      width: 1rem !important;
      height: 1rem !important;
      padding: 1rem;
      margin: 0 !important;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      outline: none !important;
      &:before {
        background: rgba(255, 255, 255, 0.3);
        width: 100%;
        height: 100%;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        content: '';
      }
    }

    & .swiper-pagination-bullet.swiper-pagination-bullet-active {
      opacity: 1 !important;
      background: $primary !important;
      z-index: 1;
    }
  }
  & .swiper-slide {
    outline: 0 !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    pointer-events: none;
    & .swiper-item {
      pointer-events: all;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform: scale(0.6);
      filter: brightness(50%);
      will-change: transform;
      opacity: 0;
      display: grid;
    }
    & .swiper-img {
      height: 100%;
      width: 100%;
      max-height: 700px;
      aspect-ratio: 1280 / 720;
      object-fit: contain;
      display: block;
      object-fit: cover;
      transform: scale(1);
      box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.2);
      // clip-path: polygon(16% 0, 0 11%, 0% 100%, 81% 100%, 100% 90%, 100% 0%);
      --b: 10px; /* control the size */
      --b-color: #1199da;
      //   padding: var(--b);
      //   border: calc(2 * var(--b)) solid #0000;
      //   outline: 1px solid var(--b-color);
      //   outline-offset: calc(-1 * var(--b));
      //   background: conic-gradient(
      //     from 90deg at 1px 1px,
      //     #0000 90deg,
      //     var(--b-color) 0
      //   );
    }
    &-prev,
    &-next {
      & .swiper-item {
        opacity: 1;
        transform: scale(0.8);
      }
    }
    &-active {
      & .swiper-item {
        --glow-color: rgb(0, 0, 0);
        --glow-spread-color: rgba(191, 123, 255, 0.781);
        --enhanced-glow-color: rgb(231, 206, 255);
        --btn-color: rgb(100, 61, 136);
        color: var(--btn-color);
        background-color: var(--glow-color);
        box-shadow: 0 0 1em 0.25em var(--glow-color),
          0 0 4em 2em var(--glow-spread-color),
          inset 0 0 0.75em 0.25em var(--glow-color);
        opacity: 1;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: scale(1) translate(0);
        opacity: 1;
        position: relative;
        z-index: 2;
        filter: none;
      }
    }
  }
  & .swiper_buttons {
    display: flex;
    justify-content: center;
  }
  & .swiper-button {
    background: $primary;
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 100%;
    color: white !important;
    z-index: 2;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin: 0 1rem;
    &:hover {
      filter: brightness(120%);
    }
    position: relative !important;
    &-prev {
      right: 0 !important;
      left: 0 !important;
      &:after {
        content: '' !important;
      }
    }
    &-next {
      right: 0 !important;
      left: 0 !important;
      &:after {
        content: '' !important;
      }
    }
  }
}

.swiper-container-cards {
  max-width: 100%;
  overflow: visible !important;
  margin-bottom: 5rem;
  & .swiper-wrapper {
    align-items: center;
    &:hover {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;
    }
    &:active {
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
  & .swiper-pagination {
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    position: relative !important;
    bottom: 0 !important;
    transform: translateY(50px);
    z-index: 3;
    & .swiper-pagination-bullet {
      background-color: transparent !important;
      color: white;
      opacity: 0.3;
      position: relative;
      display: flex !important;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      line-height: 1rem;
      width: 1rem !important;
      height: 1rem !important;
      padding: 1rem;
      margin: 0 !important;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      outline: none !important;
      &:before {
        background: rgba(255, 255, 255, 0.3);
        width: 100%;
        height: 100%;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(0.8);
        content: '';
      }
    }

    & .swiper-pagination-bullet.swiper-pagination-bullet-active {
      opacity: 1 !important;
      background: $primary !important;
      z-index: 1;
    }
  }
  & .swiper-slide {
    outline: 0 !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    pointer-events: none;
    width: 350px !important;
    & .swiper-item {
      pointer-events: all;
      transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
      transform: scale(0.6);
      filter: contrast(50%);
      opacity: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .swiper-img {
      border-radius: 100%;
      object-fit: cover;
      display: block;
      object-fit: cover;
      transform: scale(1);
    }
    &-prev,
    &-next {
      & .swiper-item {
        opacity: 1;
        transform: scale(0.6);
      }
    }
    &-active {
      & .swiper-item {
        opacity: 1;
        transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transform: scale(1) translate(0);
        opacity: 1;
        position: relative;
        z-index: 2;
        filter: none;
      }
    }
  }
  & .swiper_buttons {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  & .swiper-button {
    background: $primary;
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 100%;
    color: white !important;
    z-index: 2;
    transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    margin: 0 1rem;
    &:hover {
      filter: brightness(120%);
    }
    position: relative !important;
    &-prev {
      right: 0 !important;
      left: 0 !important;
      &:after {
        content: '' !important;
      }
    }
    &-next {
      right: 0 !important;
      left: 0 !important;
      &:after {
        content: '' !important;
      }
    }
  }
  & .card-body {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
