.home-grid {
  // max-width: 1800px;
  // padding: 0 10vw;
  // min-height: 100vh;
  // margin: auto;
  // display: flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // width: 100%;
  // gap: 4rem;
  // align-items: center;
  margin-top: 300px;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
  gap: 4rem;
  // padding-bottom: 20vh;
  flex-wrap: wrap;
  @media screen and (max-width: 1800px) {
    max-width: 90vw;
  }
  @media screen and (max-width: 1600px) {
    display: flex;
  }
  @media screen and (max-width: 1400px) {
    gap: 2rem;
  }
  @media screen and (max-width: 1100px) {
    margin-top: 300px;
    column-gap: 4rem;
  }
  @media screen and (max-width: 700px) {
    max-width: 100vw;
    gap: 0;
    justify-content: space-between;
    max-width: 80vw;
    & > div:nth-child(even) {
      margin-top: 100px;
    }
    & > div:nth-child(odd) {
      margin-top: -40px;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 180px;
  }
}
.home-grid_item {
  aspect-ratio: 1 / 1;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: block;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  border-radius: 100%;
}
.home-grid_item--disabled {
  filter: grayscale(1);
  pointer-events: none;
  user-select: none;
}
.home-grid_item_image-mask {
  clip-path: circle(50% at 50% 50%);
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    z-index: 3;
    mix-blend-mode: lighten;
    pointer-events: none;
    opacity: 0;
  }
}
.home-grid_item__light-sweep {
  z-index: 2;
  width: 100%;
  border-radius: 100%;
  overflow: hidden;
  aspect-ratio: 1 / 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  &:before {
    transition: opacity 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    content: '';
    position: absolute;
    height: 300%;
    width: 300%;
    top: -120%;
    left: -120%;
    background: linear-gradient(
      transparent 0%,
      rgba(255, 255, 255, 0.1) 45%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.1) 55%,
      transparent 100%
    );
    transform: rotate(-45deg);
  }
}
.home-grid_item__bottom-section {
  overflow: hidden;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  clip-path: polygon(0 80%, 100% 80%, 100% 100%, 0% 100%);
  @include media-breakpoint-down(sm) {
    clip-path: polygon(0 70%, 100% 70%, 100% 100%, 0% 100%);
  }
  border-radius: 100%;
  content: '';
  position: absolute;
  width: 100%;
  background: rgb(198, 0, 198);
  background: -moz-radial-gradient(
    circle,
    rgba(198, 0, 198, 1) 0%,
    rgba(128, 0, 128, 1) 67%,
    rgba(59, 0, 59, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(198, 0, 198, 1) 0%,
    rgba(128, 0, 128, 1) 67%,
    rgba(59, 0, 59, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(198, 0, 198, 1) 0%,
    rgba(128, 0, 128, 1) 67%,
    rgba(59, 0, 59, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c600c6",endColorstr="#3b003b",GradientType=1);
  height: 100%;
  left: 50%;
  bottom: 0;
  z-index: 1;
  transform: translateX(-50%) scale(0.95);
  & svg {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.home-grid_item::after {
  content: '';
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  aspect-ratio: 1 / 1;
  display: block;
  transform: translateY(0, -110%);
  border: 5px solid purple;
  border-radius: 100%;
  transform: scale(1.1);
  -webkit-mask-image: linear-gradient(to top, transparent 0%, black 100%);
  mask-image: linear-gradient(to top, transparent 0%, black 100%);
}
.home-grid_item_image {
  width: 100%;
  aspect-ratio: 1 / 1;
  animation: imgScale 20s alternate linear infinite;
  object-fit: cover;
  &-mask {
    width: 350px;
    aspect-ratio: 1 / 1;
    @media screen and (max-width: 1600px) {
      width: 300px;
    }
    @media screen and (max-width: 700px) {
      width: 150px;
    }
    margin: auto;
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    border-radius: 100%;
    transform: translateZ(0);
    overflow: hidden;
  }
}
.home-grid_item:hover {
  box-shadow: 0px 0px 30px rgb(255, 160, 255), 0px 0px 60px rgb(187, 10, 187),
    0px 0px 130px rgb(98, 0, 98);
  & .home-grid_item__light-sweep {
    &:before {
      left: 100%;
      top: 100%;
      opacity: 0;
      transition: all 2s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }
  & .home-grid_item_image-mask {
    transform: scale(0.95);
    &:before {
      background: url('./noise.webp') center center/350px repeat;
      opacity: 0.2;
    }
  }
  & .home-grid_item__bottom-section {
    transform: translateX(-50%) scale(1);
  }
  @include media-breakpoint-up(sm) {
    & .curved-text {
      transform: translate(-50%, -50%) scale(1.3) rotate(-50deg);
    }
  }
}
@keyframes imgScale {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.curved-text {
  position: absolute;
  pointer-events: none;
  z-index: 1;
  top: 50%;
  left: 50%;
  transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate(-50%, -50%) scale(1.3) rotate(-55deg);
  transform-origin: center;
  & text {
    font-size: 0.5rem;
  }
}
