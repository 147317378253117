form {
	width: 100%;
}
label {
	width: 100%;
	display: flex;
	flex-direction: column;
}
input,
textarea {
	width: 100%;
}
input,
textarea,
button {
	padding: 0.5rem 1rem;
	display: block;
	white-space: normal;
	border: 0;
	outline: 0;
	margin-bottom: 1rem;
}
input:placeholder {
	color: #7e8090;
}
button {
	border-radius: 5rem;
	font-size: 1.25rem;
	padding: 0.75rem 1.5rem;
}
.emoji {
	margin-right: 1rem;
}
.form .badge,
form .badge {
	font-size: 1rem;
	margin-bottom: 1rem;
	font-weight: normal;
}
label {
	& + input {
		margin-top: 1rem;
	}
	& + textarea {
		margin-top: 1rem;
	}
	&.checkbox {
		background: rgba(255, 255, 255, 0.1);
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 1rem;
		padding-left: 1rem;
		&:hover {
			cursor: pointer;
			background: rgba($primary, 0.2);
		}
		& span {
			padding: 0.5rem 1rem;
			margin-left: -2.6rem;
			padding-left: 3rem;
			width: calc(100% + 2.6rem);
		}
		& input {
			width: auto;
			margin-bottom: 0;
			&:checked + span {
				background: rgba($primary, 0.2);
			}
		}
	}
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
	vertical-align: baseline;
	border: 0;
	outline: 0;
	color: #a7ffff;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

textarea {
	overflow: auto;
	vertical-align: top;
}

[placeholder]::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}
[placeholder]:hover::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.4);
}
[placeholder]:focus::-webkit-input-placeholder {
	color: rgba(255, 255, 255, 0.3);
}

[placeholder]::-moz-placeholder {
	color: rgba(255, 255, 255, 0.8);
}
[placeholder]:hover::-moz-placeholder {
	color: rgba(255, 255, 255, 0.4);
}
[placeholder]:focus::-moz-placeholder {
	color: rgba(255, 255, 255, 0.3);
}

[placeholder]:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.8);
}
[placeholder]:hover:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.4);
}
[placeholder]:focus:-ms-input-placeholder {
	color: rgba(255, 255, 255, 0.3);
}

input:focus,
label:focus-within,
textarea:focus {
	box-shadow: 0px 0px 2px #d690ff;
}

input[type='text'],
input[type='number'] {
	display: block;

	margin: 0 0 20px;
	padding: 8px 12px 10px 12px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.25);
}

textarea {
	display: block;
	height: 150px;
	margin: 0 0 20px;
	padding: 8px 12px 10px 12px;
	border: 1px solid rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.25);
}

input[type='radio'] {
	vertical-align: middle;
	opacity: 0;
}

input[type='radio'] + label:before {
	content: '';
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: -3px 5px 0 -15px;
	vertical-align: middle;
	font-size: 14px;
	line-height: 19px;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.5);
	background: rgba(0, 0, 0, 0.25);
}

input[type='radio'] + label:before {
	border-radius: 50%;
}

input[type='radio']:hover + label:before {
	content: '\f111';
	font-size: 9px;
	color: rgba(255, 255, 255, 0.4);
}

input[type='radio']:checked + label:before {
	content: '\f111';
	font-size: 9px;
	color: rgba(255, 255, 255, 0.8);
}
