.richtext {
    a {
        color: tint($primary, 30%);
        text-decoration: underline;
    }
    img {
        margin-bottom: 1rem;
        max-height: 300px;
    }
    li {
        margin-bottom: 1.5rem;
    }
    img {
        width: 100%;
        // object-fit: cover;
        height: auto;
        object-fit: contain;
    }
}
