.component-wrapper {
	padding-top: 50px;
	padding-bottom: 50px;
}
// .textBlock {
//     justify-content: center;
//     display: grid;
//     grid-template-columns: 500px 500px;
//     width: 100%;
//     @include media-breakpoint-up(lg) {
//         grid-template-columns: 700px 700px;
//     }
//     @media screen and (min-width: 1500px) {
//         transform: scale(1);
//     }
//     @include media-breakpoint-down(md) {
//         display: flex;
//         flex-direction: column;
//     }
//     &__content {
//         @include media-breakpoint-up(lg) {
//             padding: 4rem;
//         }
//         padding: 1rem;
//         position: relative;
//         z-index: 1;
//     }
//     &__image,
//     &__image img {
//         width: 500px;
//         height: 500px;
//         @include media-breakpoint-up(lg) {
//             width: 700px;
//             height: 700px;
//         }
//         object-fit: cover;
//     }
//     &--right {
//         @include media-breakpoint-up(lg) {
//             margin-bottom: -500px;
//         }
//         margin-bottom: -150px;
//         &__image img {
//             transform: translate(50px);
//             @include media-breakpoint-only(lg) {
//                 transform: translate(150px);
//             }
//             @include media-breakpoint-down(md) {
//                 transform: translate(-5vw);
//                 width: 90vw;
//             }
//         }
//         &__content {
//             transform: translate(-50px, -550px);
//             @include media-breakpoint-only(lg) {
//                 transform: translate(-150px, -550px);
//             }
//             grid-row: 2;
//             grid-column: 2;
//             @include media-breakpoint-down(md) {
//                 transform: translate(5vw, -150px);
//                 width: 95vw;
//                 padding-bottom: 100px;
//             }
//         }
//     }
//     &--left {
//         @include media-breakpoint-up(lg) {
//             margin-bottom: -600px;
//         }
//         @include media-breakpoint-up(md) {
//             margin-bottom: -150px;
//         }
//         &__image {
//             transform: translate(-50px, -600px);
//             @include media-breakpoint-only(lg) {
//                 transform: translate(-150px, -600px);
//             }
//             grid-row: 2;
//             grid-column: 2;
//             @include media-breakpoint-down(md) {
//                 transform: none;
//                 transform: translate(10vw);
//                 width: 90vw;
//             }
//         }
//         &__content {
//             transform: translate(50px);
//             @include media-breakpoint-only(lg) {
//                 transform: translate(150px);
//             }
//             @include media-breakpoint-down(md) {
//                 transform: translate(0, -150px);
//                 width: 95vw;
//                 order: 2;
//             }
//             @include media-breakpoint-down(xs) {
//                 transform: translate(-5vw, -150px);
//             }
//         }
//     }
//     &--form {
//         display: block;
//         &__image {
//             margin: auto;
//             display: block;
//             margin-bottom: -30%;
//         }
//         &__content {
//             display: grid;
//         }
//     }
// }

.textBlock {
	justify-content: center;
	display: grid;
	grid-template-columns: 700px 1fr;
	justify-content: center;
	align-items: stretch;
	width: 100%;

	@include media-breakpoint-down(lg) {
		display: block;
	}
	&__content {
		padding: 2rem;
		width: 100%;
		@include media-breakpoint-down(sm) {
			padding: 1rem;
		}
	}
	&__image {
		display: grid;
		place-content: center;
		padding-left: 2rem;
		padding-right: 2rem;
		object-fit: contain;
		height: max-content;
		@include media-breakpoint-down(sm) {
			padding: 0;
		}
		& img {
			margin-top: 2rem;
			object-fit: contain;
			width: 100%;
			height: auto;
			object-position: top;
			@include media-breakpoint-down(sm) {
				margin-top: 0rem;
			}
		}
	}
	// &--right {
	//     &__image img {
	//     }
	//     &__content {
	//     }
	// }
	// &--left {
	//     &__image {
	//     }
	//     &__content {
	//     }
	// }
	&--form {
		background-color: rgb(33 38 127 / 66%);
		max-width: 1000px;
		margin: auto;

		&_image {
			padding: 0;
		}
		display: block;
	}
}
.form {
	&_checkboxes {
		margin-bottom: 1rem;
	}
	&--twocol {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
		& .field {
			grid-column: 1 / span 2;
		}
		@include media-breakpoint-down(sm) {
			display: block;
		}
	}
}
