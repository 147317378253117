.bg-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	pointer-events: none;
	user-select: none;
	z-index: -1;
	overflow: hidden;
}
.bg,
.overlay,
.decoration {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	background-repeat: no-repeat;
	height: 100%;
}
.bg {
	background-size: cover;
	background-position: center center;
	z-index: 1;
	animation: bgScale 45s alternate linear infinite;
}
.overlay {
	background: $primary-dark;
	opacity: 0.8;
	z-index: 2;
}
.decoration {
	background-size: auto 100%;
	background-position: top left;
	z-index: 5;
	animation: decoration 10s alternate ease-in-out infinite;
	@include media-breakpoint-down(sm) {
		background-size: 200%;
		background-position: 55% -10%;
	}
}

@keyframes decoration {
	0% {
		transform: translateX(-500) scale(1);
	}
	100% {
		transform: translateX(0) scale(1.1);
	}
}

@keyframes bgScale {
	0% {
		transform: scale(1.1);
	}
	100% {
		transform: scale(1.5);
	}
}
