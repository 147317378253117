.cards--horizontal {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: center;
	& .card {
		width: 30%;
		@include media-breakpoint-up(md) {
			margin-left: 1rem;
		}
	}
	@include media-breakpoint-down(lg) {
		display: block;
		& .card {
			width: 100%;
		}
	}
}
.card {
	background-color: rgba(0, 0, 0, 0.2);
	& h1 {
		font-size: 2rem;
		line-height: 2rem;
	}
	&-img-top {
		height: 300px;
		object-fit: cover;
	}
	&-body {
		height: 100%;
	}
	& .richtext {
		& h1 {
			margin-bottom: 1rem;
		}
	}
	&--horizontal {
		// display: grid;
		// grid-template-columns: 1fr;
		// grid-template-rows: 1fr 1fr;
		// @include media-breakpoint-down(lg) {
		//     display: grid;
		//     grid-template-columns: 3fr 4fr;
		// }
		// @include media-breakpoint-down(lg) {
		//     margin-bottom: 3rem;
		//     grid-template-rows: auto;
		// }
		// @include media-breakpoint-down(xs) {
		//     grid-template-columns: 1fr;
		// }
		@include media-breakpoint-down(lg) {
			display: grid;
			grid-template-columns: 30% 70%;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-down(md) {
			grid-template-columns: 35% 65%;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 40% 60%;
		}
		@include media-breakpoint-only(xs) {
			display: block;
		}
		text-align: left;
		& .card-img-top {
			height: 100%;
			height: 400px;
			@include media-breakpoint-down(lg) {
				height: 100%;
			}
		}
	}
}
