.nav {
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'left logo right';
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  z-index: 99;
  font-weight: bold;
  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'logo right';
  }
  &_logo {
    z-index: 100;
    position: relative;
    grid-area: logo;
    text-align: left;
    pointer-events: all;
    &-no-interaction {
      pointer-events: none;
    }
    @include media-breakpoint-only(xs) {
      transform-origin: top left;
      transform: scale(0.8);
      padding: 0 !important;
    }
    & svg {
      transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &-initial {
      & svg {
        transform: scale(3) translateY(15vh) translateX(0);
        @include media-breakpoint-down(md) {
          transform: scale(3) translateY(13vh) translateX(12vw);
        }
        @include media-breakpoint-down(sm) {
          transform: scale(3) translateY(13vh) translateX(10vw);
        }
        @include media-breakpoint-only(xs) {
          transform: scale(2) translateY(20vh) translateX(12vw);
        }
      }
    }
    &-animate {
      & svg {
        transform: scale(1) translateY(0) translateX(0);
        @include media-breakpoint-down(md) {
          transform: scale(0.6) translateY(-2rem) translateX(-2rem);
        }
        @include media-breakpoint-only(xs) {
          transform: scale(0.65) translateY(-3rem) translateX(-3rem);
        }
      }
    }
  }
  &_hamburger {
    position: fixed;
    pointer-events: all;
    top: 3rem;
    right: 4rem;
    transform: scale(2);
    z-index: 100;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    @include media-breakpoint-up(lg) {
      opacity: 0;
      pointer-events: none;
    }
    @include media-breakpoint-only(xs) {
      transform: scale(1.5);
      top: 1rem;
      right: 2rem;
      & .hamburger-react:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        transform: scale(1.5);
        background: rgba(rgb(56, 7, 56), 0.7);
        position: absolute;
        display: block;
      }
    }
  }
  &_item {
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none;
    @include media-breakpoint-down(lg) {
      width: 100%;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      padding: 1rem;
    }

    @include media-breakpoint-only(xs) {
      padding: 1rem;
    }
    position: relative;
    overflow: hidden;
    &:before {
      content: '';
      width: 7rem;
      height: 7rem;
      position: absolute;
      left: 50%;
      top: 50%;
      pointer-events: none;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.1) rotate(-45deg);
      background-color: rgba(255, 255, 255, 0.1);
      overflow: hidden;
      clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
      transition: all 0.3s cubic-bezier(0.25, 1, 0.5, 1);
    }
    &:hover {
      &:before {
        opacity: 1;
        transform: translate(-50%, -50%) scale(0.9);
      }
    }
  }
  &_left,
  &_right {
    @include media-breakpoint-down(lg) {
      display: none;
    }
    & .nav_item {
      padding: 3rem 2rem;
    }
    display: flex;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  &_left {
    grid-area: left;
    justify-content: flex-end;
  }
  &_right {
    grid-area: right;
    justify-content: flex-start;
  }
}
.nav__mobile-menu,
.nav__mobile-grass {
  display: none;
}
@include media-breakpoint-down(lg) {
  .nav__mobile-overlay {
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 98;
    backdrop-filter: blur(15px);
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
  }
  .nav__mobile-menu {
    display: block;
    overscroll-behavior: contain;
    position: fixed;
    height: 100vh;
    padding-bottom: 100vh;
    width: 100vw;
    overflow-y: scroll;
    z-index: 99;
    top: 0;
    left: 0;
    padding-top: 150px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    &__item {
      text-decoration: none;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      position: relative;
      aspect-ratio: 1 / 1;
      text-align: center;
      font-size: 1rem;
      line-height: 1;
      width: 30vw;
      transition: transform 0.2s cubic-bezier(0.11, 0, 0.5, 0);
      &:active {
        transform: scale(2);
        background-color: rgba($secondary, 0.5);
      }
      &:before {
        content: '';
        width: 100%;
        height: 100%;
        top: 50%;
        left: 50%;
        z-index: -1;
        display: block;
        pointer-events: none;
        position: absolute;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        background-color: rgba(255, 255, 255, 0.1);
        box-shadow: inset 0px 0px 25px $secondary;
        transform: translate(-50%, -50%) scale(0.9);
      }
    }
  }
  .nav__mobile-grass {
    display: block;
    position: fixed;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(15px);
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    z-index: 98;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    & iframe {
      mask-image: linear-gradient(to top, black 55%, transparent 95%);
      left: 50%;
      transform: translateX(-50%);
      width: 1500px; // height: 300px;
      filter: brightness(70%);
      aspect-ratio: 3840 / 600;
      position: fixed;
      bottom: 0;
    }
  }
  .nav-open {
    & .nav__mobile-grass {
      opacity: 1;
      transition: all 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
      pointer-events: all;
    }
  }
}

.nav_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  position: relative;
}

.nav_item_light-sweep {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1 / 1;
  width: 150px;
  height: 150px;
  opacity: 0;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.nav_item_light-sweep:after {
  transition: opacity 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  content: '';
  position: absolute;
  height: 300%;
  width: 300%;
  top: -120%;
  left: -120%;
  background: linear-gradient(
    transparent 0%,
    rgba(255, 255, 255, 0.1) 45%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.1) 55%,
    transparent 100%
  );
  transform: rotate(-45deg);
  opacity: 0.6;
}
.nav_item_icon {
  display: block;
  width: 2.6rem;
  transform: scale(0.8);
  height: 2.6rem;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.nav__mobile-menu__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  position: relative;
}
.nav__mobile-menu__item__icon {
  display: block;
  width: 2.6rem;
  transform: scale(0.8);
  height: 2.6rem;
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* HOVER */
.nav_item:hover .nav_item_icon {
  transform: translateY(0.25rem);
}

.nav_item:hover .nav_item_light-sweep {
  opacity: 1;
}

.nav_item:hover .nav_item_light-sweep:after {
  left: 100%;
  top: 100%;
  opacity: 0;
  transition: all 3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nav_hamburger_icon {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 0.2rem;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.nav_hamburger_icon span {
  width: 0.3rem;
  height: 0.3rem;
  background-color: #fff;
  border-radius: 100%;
  display: block;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}
.nav_hamburger_icon {
  position: relative;
}

.nav_hamburger_icon::before {
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  content: '[';
  left: -1rem;
  top: 50%;
  font-size: 1rem;
  position: absolute;
  transform: translateY(-50%) scale(0.5);
  opacity: 0;
  transform-origin: center left;
}
// .nav_hamburger_icon::after {
//   transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
//   content: ']';
//   right: -1rem;
//   top: 50%;
//   font-size: 1rem;
//   position: absolute;
//   transform-origin: center right;
//   transform: translateY(-50%) scale(0.5);
//   opacity: 0;
// }
.nav_hamburger {
  padding: 1.25rem 0.5rem 1rem 2rem;
}
.nav_hamburger_icon {
  & .hb1 {
    grid-column: 1;
    grid-row: 2;
  }
  & .hb2 {
    grid-column: 1;
    grid-row: 3;
  }

  & .hb3 {
    grid-column: 2;
    grid-row: 1;
  }
  & .hb4 {
    grid-column: 2;
    grid-row: 2;
  }
  & .hb5 {
    grid-column: 2;
    grid-row: 3;
  }
  & .hb6 {
    grid-column: 2;
    grid-row: 4;
  }

  & .hb7 {
    grid-column: 3;
    grid-row: 2;
  }
  & .hb8 {
    grid-column: 3;
    grid-row: 3;
  }
  &:hover .hb1,
  &:hover .hb2 {
    transform: translateX(-0.2rem);
  }
  &:hover .hb7,
  &:hover .hb8 {
    transform: translateX(0.2rem);
  }
  &--open .hb1,
  &--open .hb2 {
    transform: translateX(-0.1rem);
  }
  &--open .hb7,
  &--open .hb8 {
    transform: translateX(0.1rem);
  }
  &--open:before {
    transform: translateY(-50%) scale(1);
    opacity: 1;
  }
  &--open:hover .hb1,
  &--open:hover .hb2 {
    transform: translateX(-0.1rem);
  }
  &--open:hover .hb7,
  &--open:hover .hb8 {
    transform: translateX(0.1rem);
  }
  // &--open:after {
  //   transform: translateY(-50%) scale(1);
  //   opacity: 1;
  // }
  // &:hover .hb3,
  // &:hover .hb4,
  // &:hover .hb5,
  // &:hover .hb6 {
  //   transform: translateX(-0.2rem);
  // }
  // &:hover .hb1 {
  //   transform: translateY(0.2rem);
  // }
  // &:hover .hb2 {
  //   transform: translateY(-0.2rem);
  // }
  // &:hover .hb7 {
  //   transform: translateY(-0.2rem);
  // }
  // &:hover .hb8 {
  //   transform: translateY(0.2rem);
  // }
}
