.footer {
	padding: 2rem;
	position: relative;
	padding-bottom: 100px;
	z-index: 0;
	min-height: 50vh;
	@include media-breakpoint-down(sm) {
		padding: 0.5rem;
	}
	@keyframes fadein {
		100% {
			opacity: 1;
		}
	}
	&__grass {
		opacity: 0;
		animation: fadein 2s ease-in-out forwards 0.5s;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 1500px; // height: 300px;
		filter: brightness(70%);
		@include media-breakpoint-up(md) {
		}
		aspect-ratio: 3840 / 600;
		pointer-events: none;
		mask-image: linear-gradient(to right, transparent 5%, black 25%, black 75%, transparent 95%);
		mix-blend-mode: lighten;
	}
	&__bg {
		animation: fadein 2s ease-in-out forwards 0.5s;
		opacity: 0;
		&:before {
			content: "";
			position: absolute;
			background-color: black;
			width: 100%;
			height: 100%;
			z-index: 0;
			left: 0;
			top: 0;
			opacity: 0.3;
		}
		background-color: rgba(0, 0, 0, 0.3);
		background-size: cover;
		background-position: bottom center;
		background-repeat: no-repeat;
		max-width: 2560px;
		transform-origin: bottom center;
		margin: auto;
		mask-image: radial-gradient(ellipse 56% 100% at 50% 100%, black 60%, transparent 90%);
		position: absolute;
		width: 100%;
		height: 120%;
		left: 50%;
		transform: translateX(-50%) scaleX(1.3);
		pointer-events: none;
		z-index: -1;
		top: -20%;
	}
	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		gap: 2rem;
		padding: 1rem;
	}
	&__text {
		display: inline-block;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		font-weight: 400;
		height: 100%;
		& a {
			text-underline-offset: 5px;
			text-decoration-thickness: 2px;
			text-decoration-color: $primary;
			&:hover {
				background-color: $primary;
				color: black !important;
			}
		}
	}
	&__logos {
		width: max-content !important;
		text-align: left;
		@include media-breakpoint-down(sm) {
			text-align: center;
		}
		& .richtext a {
			text-underline-offset: 5px;
			text-decoration-thickness: 2px;
			color: white !important;
			text-decoration-color: $primary;
			&:hover {
				background-color: $primary;
				color: black !important;
			}
		}
	}
}
