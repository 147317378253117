.faq-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.faq-grid {
	grid-template-columns: repeat(auto-fit, minmax(200px, 500px));
	display: grid;
	justify-content: center;
}
.faq {
	&__header {
		user-select: none;
		background-color: rgba(255, 255, 255, 0.1);
		border-radius: 5px;
		padding: 0.5rem 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
		&:hover {
			cursor: pointer;
			background-color: rgba(255, 255, 255, 0.2);
		}
	}
	&__content {
		margin-top: 0.3rem;
		background-color: rgba($primary-dark, 0.5);
		border-radius: 5px;
		padding: 0.5rem 1rem;
		color: #bbbafe;
	}
}
