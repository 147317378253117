html,
body,
#root {
	max-width: 100vw;
	width: 100vw;
	margin: 0;
	padding: 0;
	overflow: overlay;
	overflow-x: hidden;
	position: relative;
	min-height: 100vh;
}
#root {
	display: flex;
	flex-direction: column;
}

hr {
	height: 10px !important;
	background: $primary;
	width: 70%;
	margin: auto;
	margin-top: 2rem;
	margin-bottom: 2rem;
	animation: hr 1s forwards ease-in-out;
	opacity: 1;
}
@keyframes hr {
	from {
		transform: scaleX(0);
	}
	to {
		transform: scaleX(1);
	}
}

.overflow-hidden {
	-webkit-mask-image: -webkit-radial-gradient(white, black);
}

/* output */
::-webkit-scrollbar-thumb {
	background-color: $secondary;
}
::-webkit-scrollbar-track {
	background-color: rgb(42, 10, 49);
}
::-webkit-scrollbar-corner {
	background-color: green;
}
::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}
body {
	-ms-overflow-style: auto;
	scrollbar-color: $secondary green;
	scrollbar-width: thin;
}
ol {
	li {
		margin-left: 2rem;
	}
	li::marker {
		font-size: 2rem;
	}
}
ol {
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 0;
}
